<template>
  <div class="topup-search-content">
    <div class="topup-iccid">
      <a-input v-model="simNumber" disabled >
        <div slot="addonBefore">{{ $t('mycards.iccid') }}</div>
      </a-input>
    </div>
    <div class="topup-search-divider" v-if="isSearch">
      <div class="line"></div>
    </div>
    <div class="topup-search flex-center-between" v-if="isSearch">
      <a-input v-model="keyword" @pressEnter="handleSearch" :placeholder="$t('route.search_tips')" allowClear>
        <div slot="addonBefore">{{ $t('mycards.destination') }}</div>
      </a-input>
      <a-button
        @click="handleSearch"
        slot="suffix"
        class="search-btn flex"
        size="large"
        type="primary"
      >
        <a-icon type="search"/>
      </a-button>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'TopupSearch',
    props: {
      iccid: {
        type: String,
        default: '',
      },
      isSearch:{
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        keyword:this.$route.query.keyword,
      }
    },
    computed: {
      simNumber() {
        return this.iccid
          .trim()
          .replace(/\s/g, "")
          .replace(/(\d{4})/g, "$1 ")
          .trim();
      },
    },
    mounted() {
    },
    methods: {
      handleSearch(){
        if(!this.keyword) return
        this.$router.push({name:'Search',query:{keyword:this.keyword,iccid:this.iccid}})
      }
    }, 
  }
</script>
<style lang="scss" scoped>
  .topup-search-content{
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:80px;
    border-radius: 38px;
    border: 1px solid #8592A6;
    .topup-iccid,.topup-search{
      flex:1;
      padding: 16px 60px;
      /deep/ .ant-input-group-wrapper{
        .ant-input-group-addon{
          font-size: 18px;
          color:#8592A6;
          padding: 0;
          border:none;
          background-color:transparent;
        }
        .ant-input{
          font-size: 18px;
          color:#222222;
          border:none;
          box-shadow:none;
        }
        .ant-input-disabled{
          background-color: transparent;
        }
      }
    }
    .topup-search-divider{
      width: 1px;
      padding: 16px 0;
      align-self: stretch;
      .line{
        height:100%;
        background-color: #8592A6;
      }
    }
    @include respond-to('pad'){
      margin-bottom:40px;
      .topup-iccid,.topup-search{
        padding: 14px 20px;
        /deep/ .ant-input-group-wrapper{
        .ant-input-group-addon{
            font-size: 16px;
          }
          .ant-input{
            font-size: 16px;
          }
        }
      }
    }
    @include respond-to('padair'){
      margin-bottom:40px;
      .topup-iccid,.topup-search{
        padding: 14px 20px;
        /deep/ .ant-input-group-wrapper{
        .ant-input-group-addon{
            font-size: 16px;
          }
          .ant-input{
            font-size: 16px;
          }
        }
      }
    }
    @include respond-to('phone'){
      border-radius: 20px;
      margin-bottom:20px;
      flex-direction: column;
      .topup-iccid,.topup-search{
        width: 100%;
        padding: 14px 16px;
        /deep/ .ant-input-group-wrapper{
        .ant-input-group-addon{
            font-size: 14px;
            @media (max-width: 320px)  {
              font-size: 12px;
            }
          }
          .ant-input{
            font-size: 14px;
            @media (max-width: 320px)  {
              font-size: 12px;
            }
          }
        }
      }
      .topup-search-divider{
        width: 100%;
        height: 1px;
        padding: 0;
      }
    }
  }
</style>